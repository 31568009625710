
import Vue from 'vue';
import PubNub from 'pubnub';
import { Api } from '@/http';

export default Vue.extend({
  data() {
    return {
      interval: null as number|null,
      client: new PubNub({
        subscribeKey: process.env.VUE_APP_PUBNUB_SUB || '',
        logVerbosity: true,
        ssl: true,
        origin: 'ps20.pndsn.com',
        keepAlive: true,
      }),
    };
  },
  mounted() {
    this.interval = window.setInterval(this.token, (60 * 60) - 15);
  },
  beforeDestroy() {
    if (this.interval) window.clearInterval(this.interval);
  },
  methods: {
    async token(): Promise<void> {
      const res = await Api().post('realtime');

      if (!res) return;

      const { clientId, token, capability } = res.data.data;

      this.client.setUUID(clientId);
      this.client.setAuthKey(token);

      const channels = Object.keys(JSON.parse(capability));

      this.client.subscribe({
        channels,
      });
    },
  },
  render: (h) => h(),
});
